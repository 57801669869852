var target;
$(".btn-choose-tab").on("click", function (e) {
  e.preventDefault();
  target = $(this).data("target");
  $(".tab-content,.btn-choose-tab").removeClass("active");
  $(target).addClass("active");
  $(this).addClass("active");
});
$(".btn-hambuger, .close-nav .btn").on("click", function (e) {
  e.preventDefault();

  if ($(".list-nav").hasClass("active")) {
    $(".list-nav").removeClass("active");
    $(".icon-hambuger").removeClass("active");
  } else {
    $(".list-nav").addClass("active");
    $(".icon-hambuger").addClass("active");
  }
});
$(".btn-show").on("click", function (e) {
  e.preventDefault();

  if ($(this).hasClass("active")) {
    $(this).removeClass("active");
    $(".functions-logged").removeClass("active");
  } else {
    $(this).addClass("active");
    $(".functions-logged").addClass("active");
  }
});

//import "./components/_scaleRoot";
// $(".inner").scaleRoot({
//     scaleMode: (viewportIsMobile($("body").outerWidth())) ? 'containWidth': 'cover'
// });
import AOS from 'aos';
import Macy from "macy";
import 'aos/dist/aos.css';
import initIcons from "./components/_svg-icons";
import "./components/_branding.js";
import "@fancyapps/fancybox";

$(function () {
  initIcons();
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 700, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

  });


  // Generate blob to download 

  $(".logo-download").on("click", function(){
    // Get link by inner imgSrc of prev element 
    var link = $(this).prev().find("img").attr("src");

    // console.log(link);

    // Create a new anchor element
    var a = document.createElement('a');
    a.href = link;
    a.download = link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

  })

});

function viewportIsMobile(width) {
  console.log("he");
  return width < 1025;
}

// window.initMacy = initMacy;
// function initMacy() {
//   // var macy = Macy({
//   //   container: " .container-macy",
//   //   trueOrder: false,
//   //   waitForImages: false,
//   //   margin: 30,
//   //   columns: 3,
//   //   breakAt: {
//   //     1200: {
//   //       margin: {
//   //         x: 30,
//   //         y: 30,
//   //       },
//   //       columns: 3
//   //     },
//   //     940: {
//   //       margin: {
//   //         x: 15,
//   //         y: 10,
//   //       },
//   //       columns: 2
//   //     },
//   //     520: {
//   //       margin: {
//   //         x: 15,
//   //         y: 10,
//   //       },
//   //       columns: 2
//   //     },
//   //     400: {
//   //       margin: {
//   //         x: 15,
//   //         y: 10,
//   //       },
//   //       columns: 2
//   //     },
//   //   },
//   // });
//   // macy.runOnImageLoad(function () {
//   //   macy.recalculate(true, true);
//   // });
// }

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Desktop: function () {
    return navigator.userAgent.match(/Windows NT/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

jQuery(document).ready(function () {
  if ($('.section--3.milestone').hasClass('en')) {
    $('.toggleContent').html('More');
  } else {
    $('.toggleContent').html('Xem thêm');
  }
  $('.section--footer .btn-scroll').click(function (e) {
    $('html, body').stop().animate({
      scrollTop: 0
    }, 1000);
  });
  if ($('.section--1 .btn-scroll').length > 0) {
    $('.section--1 .btn-scroll').click(function (e) {
      $('html, body').stop().animate({
        scrollTop: $(".section--1").outerHeight()
      }, 1000);
    });
  }
});

$(".toggleContent").click(function () {
  if ($('.section--3.milestone').hasClass('en')) {
    $('.toggleContent').html('More');
    if ($(this).parents('.milestone-item').hasClass('active')) {
      $(this).parents('.milestone-item').removeClass('active');
      $(this).html('More');
    } else {
      $('.milestone-item').removeClass('active');
      $(this).parents('.milestone-item').addClass('active');
      $(this).html('Collapse');
    }
  } else {
    $('.toggleContent').html('Xem thêm');
    if ($(this).parents('.milestone-item').hasClass('active')) {
      $(this).parents('.milestone-item').removeClass('active');
      $(this).html('Xem thêm');
    } else {
      $('.milestone-item').removeClass('active');
      $(this).parents('.milestone-item').addClass('active');
      $(this).html('Thu gọn');
    }
  }
});

$(".section--footer .main-container > li").click(function () {
  if (isMobile.any()) {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $(this).find("ul").slideUp();
    } else {
      $(".section--footer .main-container > li").removeClass('active');
      $(".section--footer .main-container > li").find("ul").slideUp();
      $(this).addClass('active');
      $(this).find("ul").slideDown();
    }
  }
});

// if ($(".container-macy").length > 0) {
//   initMacy();
// }

import "./components/_tab-content";
import "./components/_popup";
import "./components/_language";
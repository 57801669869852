// INITIALIZE
var icons = [];

// PUSH
icons.push("search", "arrow-1", "arrow-2", "globe", "people", "product", "impact", "vng", "call", "facebook", "linkedin", "instagram", "youtube", "core-value-1", "core-value-2", "core-value-3", "arrow_to_be_collapse", "arrow_to_be_extend");

const initIcons = function () {
	icons.forEach(function (v) {
		$('.svg[data-icon="' + v + '"]').html(require('../../images/svg/' + v + '.svg'));
	});
}


export default initIcons;
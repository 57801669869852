import "lazysizes";
// import Scrollbar from "smooth-scrollbar";
// import BezierEasing from "bezier-easing";

// var count = false;
// window.addEventListener(
//   "scroll",
//   function (event) {
//     var top = this.scrollY;
//     if (top >= 300 && !count) {
//       // $("#playFancy2").click();
//       // $("#playFancy1").click();
//       count = true;
//     }
//   },
//   false
// );

// SCROLL
// --
// if ($(".scrollContent").length > 0) {
//   if (!viewportIsMobile($("body").outerWidth())) {
// var vdamping = 0.1;
// let scrollbars = {};
// scrollbars["root"] = Scrollbar.init($(".scrollContent")[0], {
//   alwaysShowTracks: true,
//   damping: vdamping,
// });
// scrollbars["root"].addListener(function (status) {
//   if (status.offset.y >= 1500 && !count) {
//     $("#playFancy2").click();
//     $("#playFancy1").click();
//     count = true;
//   }
// });
// $(".btn-scroll-link").on("click", function (e) {
//   e.preventDefault();
//   var frameName = $(this).data("scroll");
//   scrollbars["root"].scrollTo(0, $(frameName)[0].offsetTop, 400, {
//     easing: (p) => {
//       var easing = BezierEasing(0.86, 0, 0.07, 1);
//       return easing(p);
//     },
//     callback: () => {},
//   });
// });
// } else {
// $(".btn-scroll-link").on("click", function (e) {
//   e.preventDefault();
//   var frameName = $(this).data("scroll");
//   $("html, body").animate({ scrollTop: $(frameName)[0].offsetTop });
// });
//     window.addEventListener(
//       "scroll",
//       function (event) {
//         var top = this.scrollY;
//         if (top >= 300 && !count) {
//           // $("#playFancy2").click();
//           // $("#playFancy1").click();
//           count = true;
//         }
//       },
//       false
//     );
//   }
// }

import Swiper from "Swiper";
import "swiper/css/swiper.min.css";

var swiper = new Swiper(".headeranim-swiper", {
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
    // renderBullet: function (index, className) {
    //   return '<span class="' + className + '">' + "0" + (index + 1) + "</span>";
    // },
  },
  navigation: {
    nextEl: ".head-next",
    prevEl: ".head-back",
  },
});
// if (viewportIsMobile($("body").outerWidth())) {
//   var swiperbenefit_thum = new Swiper(".swiper-benefit-thum", {
//     preloadImages: false,
//     // Enable lazy loading
//     lazy: {
//       loadPrevNext: true,
//     },
//     slidesPerView: "auto",
//     pagination: {
//       el: ".f3-pagination",
//       clickable: true,
//     },
//     navigation: {
//       nextEl: ".f3-next",
//       prevEl: ".f3-back",
//     },
//   });
// } else {
//   var swiperbenefit_thum = new Swiper(".swiper-benefit-thum", {
//     direction: "vertical",

//     preloadImages: false,
//     // Enable lazy loading
//     lazy: {
//       loadPrevNext: true,
//     },
//     slidesPerView: "auto",
//   });
// }

// swiperbenefit_thum.on("slideChange", function () {
//   $(".swiper-slide-thum").removeClass("active");
//   $(".swiper-slide-thum").eq(swiperbenefit_thum.realIndex).click();
// });
// var swiperbenefit = new Swiper(".swiper-benefit", {
//   autoplay: {
//     delay: 5000,
//   },
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//   },
//   slidesPerView: 1,
// });
// var targetf5;
// $(".swiper-slide-thum").click(function (e) {
//   e.preventDefault();
//   targetf5 = $(this).data("target");
//   swiperbenefit.slideTo(targetf5, 300, false);
//   swiperbenefit_thum.slideTo(targetf5, 300, false);
//   $(".swiper-slide-thum").removeClass("active");
//   $(this).addClass("active");
// });
// swiperbenefit.on("slideChange", function () {
//   $(".swiper-slide-thum").removeClass("active");
//   $(".swiper-slide-thum").eq(swiperbenefit.realIndex).addClass("active");
//   swiperbenefit_thum.slideTo(swiperbenefit.realIndex, 300, false);
// });

// var swiperrunners = new Swiper(".swiper-runners", {
//   autoplay: {
//     delay: 5000,
//   },
//   spaceBetween: 30,
//   preloadImages: false,
//   // Enable lazy loading
//   lazy: {
//     loadPrevNext: true,
//   },
//   slidesPerView: "auto",
//   pagination: {
//     el: ".swiper-pagination-runners",
//     clickable: true,
//   },
//   //loop: true,
//   navigation: {
//     nextEl: ".runner-next",
//     prevEl: ".runner-back",
//   },
// });
function viewportIsMobile(width) {
  return width < 1025;
}


// JavaScript Document
var welcome = $("#input-region").val();
//read previously set cookie value
var language = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, '$1');
var d = new Date();
d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
var expires = 'expires=' + d.toUTCString();

jQuery(document).ready(function () {

    $(".choose-language .region").removeClass('languages__current');
    if (language.length == 0) {
        document.cookie = 'language=' + welcome + ';' + expires + '; path=/';
        $('.choose-language .region[data-language=' + welcome + ']').addClass("active");
        renewChooseLanguage();
    }
    else {
        renewChooseLanguage();
    }
    // $('.choose-language').on('click', function (e) {
    //     e.preventDefault();
    //     $(this).toggleClass('open active');
    //     $(this).find('.btn-arrow').toggleClass('up');
    //     $(this).find('ul').slideToggle('fast');
    // });
    // $(".choose-language").hover(function () {
    //     $(this).toggleClass('open active');
    //     $(this).find('.btn-arrow').toggleClass('up');
    //     $(this).find('ul').slideToggle('fast');
    // });
    // $('.hassub').on('click', function (e) {
    //     e.preventDefault();
    //     $(this).toggleClass('open active');
    //     $(this).toggleClass('up');
    //     $(this).find('ul').slideToggle('fast');
    // });

    // $(".hassub").hover(function () {
    //     $(this).toggleClass('open active');
    //     $(this).toggleClass('up');
    //     $(this).find('ul').slideToggle('fast');
    // });

    $('.dropdown-content .region').on('click', function (e) {
        e.preventDefault();
        welcome = $(this).data("region");
        document.cookie = 'language=' + welcome + ';' + expires + '; path=/';
        if (!$(this).hasClass('languages__current')) {
            location.reload();
        }
    });

});

function renewChooseLanguage() {
    var x = document.cookie.replace(/(?:(?:^|.*;\s*)language\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    var chosenNationText = $('.choose-language .region[data-region=' + x + ']').find('.label').text();
    var chosenLang = x;
    var chosenNationFlag = $('.choose-language .region[data-region=' + x + ']').find('.flag').attr('class');
    var oldNationText = $('.choose-language .button--navigation').find('.label').text();
    var oldNationFlag = $('.choose-language .button--navigation').find('.flag').attr('class');
    var oldLang = $('.choose-language .button--navigation').attr('data-region');
    $('.choose-language .button--navigation').find('.flag').attr('class', chosenNationFlag);
    $('.choose-language .button--navigation').find('.flag').attr('data-language', chosenNationFlag);
    $('.choose-language .button--navigation').find('.label').text(chosenNationText);
    $('.choose-language .button--navigation ').attr('data-region', chosenLang);
    for (var i = 0; i < $('.dropdown-content .region').length; i++) {
        var indexLand = $('.dropdown-content .region').eq(i);
        if ($(indexLand).find('.label').text() === chosenNationText) {
            $(indexLand).find('.label').text(oldNationText);
            $(indexLand).attr('data-region', oldLang);
            $(indexLand).find('.flag').attr('class', oldNationFlag);
            $(indexLand).find('.flag').attr('data-language', oldLang);
        }
    }
    $('.choose-language .region[data-language=' + x + ']').addClass("active");
}

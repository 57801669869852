var popup;
$(".btn-open-popup").on("click", function (e) {
    e.preventDefault();
    popup = $(this).data("popup");
    $(popup).addClass('active');

});

if ($(".popup__background").length > 0) {
    $(".popup__background").each(function () {

        $(this).on("click", function () {
            $(this).parent().removeClass("active");
            $(".btn-search").addClass('open-popup');
            $(".btn-search").removeClass('close-popup');

        })
    })
}
$(".close-popup").click(function (e) {
    e.preventDefault();
    $(".popup").removeClass("active");

});

$(".btn-search").on("click", function (e) {
    if ($(this).hasClass('open-popup')) {
        e.preventDefault();
        popup = $(this).data("popup");
        $(popup).addClass('active');
        $(this).removeClass('open-popup');
        $(this).addClass('close-popup');
    }
    else if ($(this).hasClass('close-popup')) {
        e.preventDefault();
        $(".popup").removeClass("active");
        $(this).addClass('open-popup');
        $(this).removeClass('close-popup');
    }

});
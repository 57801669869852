window.addEventListener("load", function(){
    console.log("init _branding.js");

    $(".branding_aside__title, .branding_aside__toggle").on("click", function(){
        let $item = $(this).parents(".branding_aside__item");
        let $item_isActive = $item.hasClass("active");

        if ($item_isActive) {
            $(".branding_aside__item").removeClass("active");
            $item.removeClass("active");
        } else {
            $(".branding_aside__item").removeClass("active");
            $item.addClass("active");
        }
    });

    $(".branding_main h2").on("click", function(){
        let $item = $(this);
        let $item_isActive = $item.hasClass("active");

        if ($item_isActive) {
            // $(".branding_aside__item").removeClass("active");
            $item.removeClass("active");
        } else {
            // $(".branding_aside__item").removeClass("active");
            $item.addClass("active");
        }
    });

    $(".branding_aside__prime_toggle ").on("click", function(){
        if ($(".branding").hasClass("active")) {
            $(".branding").removeClass("active")
        } else {
            $(".branding").addClass("active")
        }
    })


    if ($(".branding_main").length > 0) {
        let currentPage = $(".branding_main").attr("data-branding-main");
        if ($(`[data-branding-aside="${currentPage}"]`).length > 0) {
            $("[data-branding-aside]").removeClass("active");
            $(`[data-branding-aside="${currentPage}"]`).addClass("active")
            $(`[data-branding-aside="${currentPage}"]`).parents(".branding_aside__item").addClass("active");
        }
    }


    var _inline_svg = [];
    _inline_svg.push("be_typo_unisectvnu", "be_typo_calibri_0", "be_typo_calibri_1", "be_typo_calibri_2", "be_typo_svngilroy", "be_typo_svngilroy_0", "be_typo_svngilroy_1", "be_typo_svngilroy_2", "be_typo_svngilroy_3", "be_typo_svngilroy_4", "be_typo_svngilroy_5", "be_typo_svngilroy_6");
    _inline_svg.forEach(function (v) {
        $('.svg[data-inline-svg="' + v + '"]').html(require('../../images/branding/' + v + '.svg'));
    });
});